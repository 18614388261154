import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Page, PageTitleBox } from '../../components/_Page'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { StyledButton } from '../../components/CustomTable/styles'
import { deleteActivity, getActivity, IActivityStatus } from '../../services'
import { QuestionForm } from './components/QuestionForm/QuestionForm'
import { IQuestionFormQuestion } from './components/QuestionForm/QuestionForm.type'
import { activityActions } from '../../store/activities/activities.reducer'
import { StyledActivityViewActions } from './ActivityView.style'
import { Modal } from '../../components/_UI'
import { ActivityForm, IActivityForm } from '../_Common'
import QuestionPreviewModal from './components/QuestionPreviewModal/QuestionPreviewModal'
import CustomModal from '../../components/CustomModal'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { Language } from '../../types'

export const ActivityView: FC = () => {
  const queryParams = useParams()

  const activityId = queryParams.id!

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const activity = useAppSelector((state) => state.activity.activity)

  const activityTitle = activity.data?.title ?? ''
  const activityDescriptions = activity.data?.description
  const activityImg = activity.data?.unit.logoUrl
  const unitId = activity.data?.unit.id ?? ''

  const [isOpenFormModal, setIsOpeFormModal] = useState(false)
  const [isOpenDeleteModal, setIsOpeDeleteModal] = useState(false)
  const [selectedQuestion, setSelectedQuestion] =
    useState<IQuestionFormQuestion | null>(null)
  const [formInitialValues, setFormInitialValues] = useState<
    IActivityForm | undefined
  >()

  const toggleFormHandler = useCallback(() => {
    setIsOpeFormModal(!isOpenFormModal)
  }, [isOpenFormModal])

  const closeDeleteModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  const formSubmitSuccessHandler = useCallback(() => {
    setIsOpeFormModal(false)
    dispatch(activityActions.setIsNeedRefetchActivity(true))
  }, [])

  const deleteActivityHandler = useCallback(() => {
    if (!activityId) return

    setIsOpeDeleteModal(false)
    dispatch(deleteActivity({ id: activityId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        navigate(`${APP_ROUTES.UNITS}/${unitId}`)
      }
    })
  }, [activityId])

  const toggleDeleteActivityHandler = useCallback(() => {
    setIsOpeDeleteModal(true)
  }, [])

  useEffect(() => {
    dispatch(getActivity({ id: activityId }))
  }, [activityId])

  const initialValue = useMemo(() => {
    const questionFromActivity = activity.data?.questions ?? []

    const questions = questionFromActivity.map(
      ({ answers: answersFromActivity, ...questionItemProps }) => {
        const answers: IQuestionFormQuestion['answers'] = []
        const wrongAnswers: IQuestionFormQuestion['wrongAnswers'] = []

        answersFromActivity.forEach((answerItem) => {
          if (answerItem.isCorrect) {
            answers.push(answerItem)
          } else {
            wrongAnswers.push(answerItem)
          }
        })
        return {
          ...questionItemProps,
          answers,
          wrongAnswers
        }
      }
    )

    return questions.length
      ? {
          questions
        }
      : undefined
  }, [activity.data?.questions])

  useEffect(() => {
    if (activity.isNeedRefetch) {
      dispatch(getActivity({ id: activityId }))
      dispatch(activityActions.setIsNeedRefetchActivity(false))
    }
  }, [activity.isNeedRefetch])

  useEffect(() => {
    if (!activity.data) return

    const {
      status,
      id,
      title,
      description,
      level,
      sceneUrl,
      grammarTags = [],
      syntaxTags = [],
      vocabTags = [],
      isDemo,
      mode
    } = activity.data

    setFormInitialValues({
      id,
      draft: status === IActivityStatus.draft,
      title,
      description,
      level,
      sceneUrl,
      grammarTags,
      syntaxTags,
      vocabTags,
      isDemo,
      mode
    })
  }, [activity.data])

  if (activity.isPending) {
    return <Page />
  }

  return (
    <Page>
      <PageTitleBox
        title={activityTitle}
        logoSrc={activityImg}
        actions={
          <StyledActivityViewActions>
            <StyledButton
              type={'button'}
              size={'large'}
              variant={'outlined'}
              color={'primary'}
              onClick={toggleFormHandler}
            >
              Edit
            </StyledButton>
            <StyledButton
              type={'button'}
              size={'large'}
              variant={'outlined'}
              color={'error'}
              onClick={toggleDeleteActivityHandler}
            >
              Delete Activity
            </StyledButton>
          </StyledActivityViewActions>
        }
      >
        {activityDescriptions}
      </PageTitleBox>

      <Box>
        <QuestionForm
          initialValues={initialValue}
          activityId={activityId}
          toggleQuestionPreview={setSelectedQuestion}
        />
      </Box>

      <CustomModal
        open={isOpenFormModal}
        title={'Edit an Activity'}
        handleClose={toggleFormHandler}
        width={'404px'}
      >
        <ActivityForm
          unitId={unitId}
          initialValues={formInitialValues}
          onSuccess={formSubmitSuccessHandler}
          onClose={toggleFormHandler}
        />
      </CustomModal>

      {selectedQuestion && activity.data && (
        <QuestionPreviewModal
          language={activity.data.unit.course.language as Language}
          question={selectedQuestion}
          toggle={setSelectedQuestion}
          activityMode={activity.data.mode}
        />
      )}

      <Modal
        isOpen={isOpenDeleteModal}
        title={'Delete activity'}
        onClose={closeDeleteModal}
        onSuccess={deleteActivityHandler}
        confirmText={'Delete'}
      >
        You are about to delete activity, this action is permanent. Do you want
        to proceed?
      </Modal>
    </Page>
  )
}
