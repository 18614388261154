import { ISchoolForm, ISchoolFormProps } from './SchoolForm.types'
import { SchoolLevel } from '../../../types'
import { FC, memo, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Formik } from 'formik'
import {
  FormDatePicker,
  FormImgLoader,
  FormRadioGroup,
  FormSwitchInput,
  FormTextInput,
  FormMultySelect
} from '../../../components/_Form'
import {
  FormContainer,
  FormImgContainer,
  FormMainContainer,
  FormSupportContainer,
  FormSupportContainerBottom
} from '../../../components/_Page'
import { getAdminCourse } from '../../../services'
import { StyledButton } from '../../../components/CustomTable/styles'
import { schoolFormSchema } from './schemas/SchoolForm.schema'
import dayjs from 'dayjs'
import { useSchoolSubmit } from './hooks/useSchoolSubmit'
import { useCourses } from './hooks/useCourses'

const baseInitialValues: ISchoolForm = {
  logoPath: undefined,
  logoUrl: undefined,
  managerFullName: '',
  managerEmail: '',
  managerPhone: '',
  courseIds: [],
  licenseEnd: '',
  licenseStart: '',
  country: '',
  longName: '',
  shortName: '',
  isCompany: false,
  level: SchoolLevel.primary,
  uai: undefined
}

export const SchoolForm: FC<ISchoolFormProps> = memo(
  ({ initialValues = baseInitialValues, onSuccess }) => {
    const dispatch = useAppDispatch()

    const isPendingSchoolFrom = useAppSelector(
      (state) => state.schools.isPendingSchoolFrom
    )

    const courses = useCourses()

    useEffect(() => {
      dispatch(getAdminCourse({ allList: true }))
    }, [])

    const formSubmit = useSchoolSubmit({ initialValues, onSuccess })

    const levelItems = useMemo(
      () =>
        Object.keys(SchoolLevel).map((levelItem) => ({
          name: levelItem,
          value: levelItem
        })),
      []
    )

    return (
      <Formik<ISchoolForm>
        initialValues={initialValues ?? baseInitialValues}
        onSubmit={formSubmit}
        validationSchema={schoolFormSchema}
      >
        {({ values, handleSubmit }) => {
          const { licenseStart } = values
          const isHasLicenseStartValue = !licenseStart

          return (
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <FormImgContainer>
                  <FormImgLoader<ISchoolForm>
                    name={'logoPath'}
                    type={'school'}
                    labelText={'Add picture'}
                    currentImgSrc={initialValues?.logoUrl}
                  />
                </FormImgContainer>
                <FormMainContainer>
                  <FormSwitchInput<ISchoolForm>
                    name={'isCompany'}
                    label={'Company'}
                  />

                  <FormTextInput<ISchoolForm>
                    name={'longName'}
                    label={'Name'}
                    placeholder={'Enter long name'}
                  />

                  <FormTextInput<ISchoolForm>
                    name={'shortName'}
                    label={'Short Name'}
                    placeholder={'Enter short name'}
                  />

                  <FormTextInput<ISchoolForm>
                    name={'country'}
                    label={'Country'}
                    placeholder={'Enter country'}
                  />

                  <FormRadioGroup<ISchoolForm>
                    label={'Type of school'}
                    name={'level'}
                    direction={'column'}
                    options={levelItems}
                  />

                  <FormTextInput<ISchoolForm>
                    name={'managerFullName'}
                    label={'Name of the manager'}
                    placeholder={'Enter name of the manager'}
                  />

                  <FormTextInput<ISchoolForm>
                    name={'managerEmail'}
                    label={'Email of the manager'}
                    type={'email'}
                    placeholder={'Enter email'}
                  />

                  <FormTextInput<ISchoolForm>
                    name={'managerPhone'}
                    label={'Phone number of the manager'}
                    placeholder={'Enter phone number'}
                    type={'phone'}
                  />

                  <FormMultySelect<ISchoolForm>
                    name="courseIds"
                    items={courses}
                    label="Course"
                    placeholder="Enter course name for search"
                  />

                  <FormDatePicker<ISchoolForm>
                    name={'licenseStart'}
                    label={'License start date'}
                  />

                  <FormDatePicker<ISchoolForm>
                    disabled={isHasLicenseStartValue}
                    name={'licenseEnd'}
                    label={'License end date'}
                    minDate={!!licenseStart ? dayjs(licenseStart) : undefined}
                    maxDate={
                      !!licenseStart
                        ? dayjs(licenseStart).add(32, 'month')
                        : undefined
                    }
                  />

                  <FormTextInput<ISchoolForm>
                    name="uai"
                    label="UAI"
                    placeholder="Enter UAI"
                    type="text"
                  />
                </FormMainContainer>
                <FormSupportContainer>
                  {/*<FormSupportContainerTop></FormSupportContainerTop>*/}
                  <FormSupportContainerBottom>
                    <StyledButton
                      type={'submit'}
                      size={'large'}
                      variant={'contained'}
                      color={'primary'}
                      disabled={isPendingSchoolFrom}
                    >
                      Confirm
                    </StyledButton>
                  </FormSupportContainerBottom>
                </FormSupportContainer>
              </FormContainer>
            </form>
          )
        }}
      </Formik>
    )
  }
)
