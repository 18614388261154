import { FC, memo, useMemo } from 'react'
import { Formik } from 'formik'
import {
  FormMultySelect,
  FormSelectInput,
  FormSwitchInput,
  FormTextInput
} from '../../../components/_Form'

import { StyledButton } from '../../../components/CustomTable/styles'
import { IActivityForm, IActivityFormProps } from './ActivityForm.types'
import { activityFormSchema } from './schemas/ActivityForm.schema'
import {
  StyledButtonContainer,
  StyledFormContainer
} from './ActivityForm.style'
import { IActivityLevel, ActivityMode } from '../../../services'
import {
  ACTIVITY_LEVELS_MAP,
  ACTIVITY_MODE_MAP,
  GRAMMARS,
  SYNTAX,
  VOCAB
} from '../../../utils/constants'
import { useActivitySubmit } from './hooks/useActivitySubmit'
import { useAppSelector } from '../../../hooks'

export const ActivityForm: FC<IActivityFormProps> = memo(
  ({ initialValues, onSuccess, unitId, onClose }) => {
    const isPendingActivityForm = useAppSelector(
      (state) => state.activity.isPendingActivityForm
    )
    const formSubmit = useActivitySubmit({
      initialValues,
      onSuccess,
      unitId
    })

    const levelItems = useMemo(() => {
      return Object.values(IActivityLevel).map((activityLevelName) => {
        const { name, level } = ACTIVITY_LEVELS_MAP[activityLevelName]

        return {
          name: `${name} ${level}`,
          value: activityLevelName
        }
      })
    }, [])

    const modeItems = useMemo(() => {
      return Object.values(ActivityMode).map((item) => {
        const { label, value } = ACTIVITY_MODE_MAP[item]

        return {
          name: label,
          value
        }
      })
    }, [])

    return (
      <Formik<IActivityForm>
        initialValues={
          initialValues ?? {
            title: '',
            description: '',
            level: IActivityLevel.a1_beginner,
            sceneUrl: '',
            draft: false,
            grammarTags: [],
            syntaxTags: [],
            vocabTags: [],
            isDemo: false,
            mode: ActivityMode.guided
          }
        }
        onSubmit={formSubmit}
        validationSchema={activityFormSchema}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <StyledFormContainer>
                <FormTextInput<IActivityForm>
                  name={'title'}
                  label={'Name of activity'}
                  placeholder={'Enter name of activity'}
                  autoComplete={'off'}
                />

                <FormSelectInput
                  name={'level'}
                  label={'Level'}
                  options={levelItems}
                />

                <FormTextInput<IActivityForm>
                  name={'sceneUrl'}
                  label={'3D scene link'}
                  placeholder={'Enter link on 3D scene'}
                  autoComplete={'off'}
                />

                <FormSelectInput
                  name={'mode'}
                  label={'Mode'}
                  options={modeItems}
                />

                <FormTextInput<IActivityForm>
                  name={'description'}
                  label={'Description'}
                  placeholder={'Enter description for activity'}
                  autoComplete={'off'}
                  multiline
                  rows={4}
                />

                <FormMultySelect<IActivityForm>
                  name="grammarTags"
                  items={GRAMMARS}
                  label="Grammar tags"
                  placeholder="Select tags"
                />
                <FormMultySelect<IActivityForm>
                  name="syntaxTags"
                  items={SYNTAX}
                  label="Syntax tags"
                  placeholder="Select tags"
                />
                <FormMultySelect<IActivityForm>
                  name="vocabTags"
                  items={VOCAB}
                  label="Vocab tags"
                  placeholder="Select tags"
                />

                <FormSwitchInput name="isDemo" label="Demo" />
                <FormSwitchInput name="draft" label="Draft" />

                <StyledButtonContainer>
                  <StyledButton
                    type={'button'}
                    size={'large'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={onClose}
                  >
                    Cancel
                  </StyledButton>

                  <StyledButton
                    type={'submit'}
                    size={'large'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={isPendingActivityForm}
                  >
                    Confirm
                  </StyledButton>
                </StyledButtonContainer>
              </StyledFormContainer>
            </form>
          )
        }}
      </Formik>
    )
  }
)
