import { FC, memo, useCallback, useMemo } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { StyledWrapper } from './QuestionPreviewModal.style'
import { IQuestionFormQuestion } from '../QuestionForm/QuestionForm.type'
import { Language } from '../../../../types'
import { ActivityMode } from '../../../../services'
import { VrSceneQuestionPreview } from 'beyond-words-vr-module'
import { v4 as uuid } from 'uuid'

interface Props {
  toggle: (newQuestion: IQuestionFormQuestion | null) => void
  language: Language
  question: IQuestionFormQuestion
  activityMode: ActivityMode
}

const QuestionPreviewModal: FC<Props> = ({
  toggle,
  question,
  language,
  activityMode
}) => {
  const prepareAnswers = useCallback(
    ({ answers, wrongAnswers }: IQuestionFormQuestion) => {
      return [
        ...answers.map((answer) => ({
          ...answer,
          id: answer.id || uuid(),
          isCorrect: true
        })),
        ...wrongAnswers.map((answer) => ({
          ...answer,
          id: answer.id || uuid(),
          isCorrect: false
        }))
      ]
    },
    []
  )
  const modQuestion = useMemo(
    () => ({
      text: question.text,
      answers: prepareAnswers(question),
      hintText: question.hintText
    }),
    [question, prepareAnswers]
  )

  const handleClose = useCallback(() => toggle(null), [toggle])

  return (
    <CustomModal
      open
      title="Question Preview"
      handleClose={handleClose}
      width="500px"
    >
      <StyledWrapper>
        <VrSceneQuestionPreview
          language={language}
          activityMode={activityMode}
          question={modQuestion}
        />
      </StyledWrapper>
    </CustomModal>
  )
}

export default memo(QuestionPreviewModal)
