import { FC, useCallback, useEffect } from 'react'
import { Box, Page, PageTitle } from '../../components/_Page'
import { SchoolForm } from '../_Common'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { getSchool, SchoolType } from '../../services'
import { useAppDispatch, useAppSelector } from '../../hooks'
import dayjs from 'dayjs'

export const EditSchool: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const queryParams = useParams()

  const { isPending: isPendingSchool, data: school } = useAppSelector(
    (state) => state.schools.school
  )

  const schoolId = queryParams.id!

  useEffect(() => {
    dispatch(getSchool({ id: schoolId }))
  }, [schoolId])

  const onSuccessHandler = useCallback(() => {
    navigate(`${APP_ROUTES.SCHOOLS}`)
  }, [])

  if (isPendingSchool || !school) {
    return <Page />
  }

  return (
    <Page>
      <Box>
        <PageTitle>Edit School</PageTitle>
        <SchoolForm
          onSuccess={onSuccessHandler}
          initialValues={{
            id: school.id,
            logoUrl: school.logoUrl,
            logoPath: school.logoPath,
            licenseStart: dayjs(school.licenseStart).format('YYYY-MM-DD'),
            licenseEnd: dayjs(school.licenseEnd).format('YYYY-MM-DD'),
            isCompany: school.type === SchoolType.company,
            level: school.level,
            country: school.country,
            courseIds: (school.courses ?? []).map(({ id }) => id),
            longName: school.longName,
            managerEmail: school.managerEmail,
            managerFullName: school.managerFullName,
            shortName: school.shortName,
            managerPhone: school.managerPhone,
            uai: school?.uai
          }}
        />
      </Box>
    </Page>
  )
}
